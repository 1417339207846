<template>
    <defaultSec :title="'< 返回'" :returnState="true">
        <div>
            <el-form :model="form" label-width="120px">
                <el-form-item label="属性id" prop="propertyId" v-show="false">
                    <el-input v-model="form.propertyId" placeholder="请输入属性id"></el-input>
                </el-form-item>
                <el-form-item label="属性名称" prop="propertyName" :required="true">
                    <el-input v-model="form.propertyName" placeholder="属性名称"></el-input>
                </el-form-item>
                <el-form-item label="属性表字段" prop="propertyField"  :required="true">
                    <el-input v-model="form.propertyField" placeholder="属性表字段"></el-input>
                </el-form-item>
                <el-form-item label="属性分类" prop="propertyClass" :required="true">
                    <el-select v-model="form.propertyClass" filterable clearable placeholder="属性分类">
                        <el-option v-for="item in propertyClassList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="属性类型" prop="propertyType" :required="true" v-show="false">
                    <el-select v-model="form.propertyType" filterable clearable placeholder="属性类型">
                        <el-option v-for="item in propertyTypeList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据类型" prop="properytDataType" :required="true" >
                    <el-select v-model="form.properytDataType" :disabled="form.propertyId>0"  filterable clearable placeholder="数据类型">
                        <el-option v-for="item in properytDataTypeList"
                                   :key="item.name"
                                   :label="item.name"
                                   :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="示例" prop="propertyDemo" :required="true">
                    <el-input v-model="form.propertyDemo" placeholder="示例"></el-input>
                </el-form-item>
                <el-form-item label="排序" :required="true">
                    <el-input-number v-model="form.sort" :min="1" :max="1000" placeholder=""></el-input-number>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="save" size="mini" class="header-btn">
                        保存
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 15,
                    currentPage: 1,
                    eventName:'',
                    eventType:'',
                    status:'',
                    eventTableName:'',
                },
                propertyTypeList:[],
                propertyClassList:[],
                properytDataTypeList:[],

                form: {
                    propertyId: this.$route.params.propertyId || 0,
                    propertyName:'',
                    propertyField:'',
                    propertyClass:1,
                    properytDataType:'String',
                    propertyType:1,
                    eventRemark:'',
                    sort:1
                },
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initPropertyTypeList()
            this.initPropertyClassList()
            this.initPropertyDataTypeList()
            if(this.form.propertyId>0){
                this.getDetail()
            }
        },
        methods: {
            getDetail() {
                this.$apiGet('lechun-cms/scrmproperty/getProperty', {propertyId: this.$route.params.propertyId}).then(res => {
                    this.form = res
                })
            },
            initPropertyTypeList(){
                this.$apiGet('lechun-cms/scrmproperty/getPropertyTypeList', {}).then(res => {
                    console.log(res)
                    this.propertyTypeList=res
                })
            },
            initPropertyClassList(){
                this.$apiGet('lechun-cms/scrmproperty/getPropertyClassList', {}).then(res => {
                    console.log(res)
                    this.propertyClassList=res
                })
            },
            initPropertyDataTypeList(){
                this.$apiGet('lechun-cms/scrmproperty/getPropertyDataTypeList', {}).then(res => {
                    console.log(res)
                    this.properytDataTypeList=res
                })
            },
            save(){
                if(this.form.propertyName==''){
                    this.$message({
                        showClose: true,
                        message: '属性名必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.propertyField==''){
                    this.$message({
                        showClose: true,
                        message: '表字段必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.propertyType==''){
                    this.$message({
                        showClose: true,
                        message: '类型必选',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.propertyClass==''){
                    this.$message({
                        showClose: true,
                        message: '分类必选',
                        type: 'error'
                    });
                    return;
                }
                this.$apiGet('lechun-cms/scrmproperty/saveProperty', this.form).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$router.push({path: '/property', name: 'property'})
                })
            },
            deleteProerty(propertyId){
                this.$confirm('删除后不可撤销，确定删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmproperty/deleteProperty', {propertyId:propertyId}).then(res => {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        console.log(res)
                    })
                }).catch(() => {
                });
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>